import React from 'react';
import { UserAssociations } from '../../types';
import { AllCategories, ReassignChangeRequestsMap, ReassignDocumentsMap, ReassignItemsMap } from './types';
import { addNewOwnerToItems } from './utils';

export type EntitiesState = {
  owner: ReassignDocumentsMap;
  changeRequestOwner: ReassignChangeRequestsMap;
  approver: ReassignDocumentsMap;
  reviewer: ReassignDocumentsMap;
  issues: ReassignItemsMap;
  tasks: ReassignItemsMap;
};

// Takes the Qualio entities that need reassigned and maps them to the new state object
export const initializeState = (itemsToReassign: UserAssociations): EntitiesState => {
  const {
    owner,
    approver: { documents: documentApprovals, periodic_reviews: periodicReviewApprovals },
    reviewer,
    issues,
    tasks,
  } = itemsToReassign;

  return {
    owner: owner.documents.reduce(addNewOwnerToItems, {}),
    changeRequestOwner: owner.change_requests.reduce(addNewOwnerToItems, {}),
    approver: [...documentApprovals, ...periodicReviewApprovals].reduce(addNewOwnerToItems, {}),
    reviewer: reviewer.documents.reduce(addNewOwnerToItems, {}),
    issues: issues.issues.reduce(addNewOwnerToItems, {}),
    tasks: tasks.tasks.reduce(addNewOwnerToItems, {}),
  };
};

// Map of all dispatchable action types by entity category
const actionMap: Record<AllCategories, string> = {
  owner: 'updateOwnerEntities',
  changeRequestOwner: 'updateChangeRequestOwnerEntities',
  approver: 'updateApproverEntities',
  reviewer: 'updateReviewerEntities',
  issues: 'updateIssuesEntities',
  tasks: 'updateTaskEntities',
} as const;

// All 'change request' category dispatches should have document map payload
export type UpdateChangeRequestMapDispatch = {
  type: (typeof actionMap)['changeRequestOwner'];
  payload: ReassignChangeRequestsMap;
};

// All 'doc' category dispatches should have document map payload
export type UpdateDocumentMapDispatch = {
  type: (typeof actionMap)['owner'] | (typeof actionMap)['approver'] | (typeof actionMap)['reviewer'];
  payload: ReassignDocumentsMap;
};

// All 'item' category dispatches should have item map payload
export type UpdateItemMapDispatch = {
  type: (typeof actionMap)['issues'] | (typeof actionMap)['tasks'];
  payload: ReassignItemsMap;
};

// Type of the "Dispatch" function used to update the Entities state
export type EntityDispatch = React.Dispatch<
  UpdateDocumentMapDispatch | UpdateItemMapDispatch | UpdateChangeRequestMapDispatch
>;

/**
 * Reducer used to manage the entities state.  It is important that an action only
 * updates the piece of state it applies to.  For instance an "owner" update should only
 * create a new "owner" object.  It should not update any of the other categories. (massive rerenders)
 * It also needs to return a new object for the category, it can't return the previous value,
 * i.e.:
 *  good:
 *    return {
 *       ...state,
 *       owner : {  ...state[key], ...update },
 *    };
 *  bad:
 *    return {
 *       ...state,
 *       owner : Object.assign(state[key], update),
 *    };
 * The "bad" version there will cause the individual tables not to re-render on updates
 */
export const reducer = (
  state: EntitiesState,
  action: UpdateDocumentMapDispatch | UpdateItemMapDispatch | UpdateChangeRequestMapDispatch,
): EntitiesState => {
  const { type, payload } = action;
  switch (type) {
    case actionMap.owner:
      return {
        ...state,
        owner: { ...payload } as ReassignDocumentsMap,
      };
    case actionMap.changeRequestOwner:
      return {
        ...state,
        changeRequestOwner: { ...payload } as ReassignChangeRequestsMap,
      };
    case actionMap.approver:
      return {
        ...state,
        approver: { ...payload } as ReassignDocumentsMap,
      };
    case actionMap.reviewer:
      return {
        ...state,
        reviewer: { ...payload } as ReassignDocumentsMap,
      };
    case actionMap.issues:
      return {
        ...state,
        issues: { ...payload } as ReassignItemsMap,
      };
    case actionMap.tasks:
      return {
        ...state,
        tasks: { ...payload } as ReassignItemsMap,
      };
    default:
      throw new Error('Received unexpected dispatch');
  }
};

export const buildDispatchObject = (
  category: string,
  entityMap: ReassignDocumentsMap | ReassignItemsMap | ReassignChangeRequestsMap,
): UpdateDocumentMapDispatch | UpdateItemMapDispatch | UpdateChangeRequestMapDispatch => {
  if (category === 'owner' || category === 'approver' || category === 'reviewer') {
    return {
      type: actionMap[category],
      payload: entityMap,
    } as UpdateDocumentMapDispatch;
  }

  if (category === 'issues' || category === 'tasks') {
    return {
      type: actionMap[category],
      payload: entityMap,
    } as UpdateItemMapDispatch;
  }

  if (category === 'changeRequestOwner') {
    return {
      type: actionMap[category],
      payload: entityMap,
    } as UpdateChangeRequestMapDispatch;
  }

  throw new Error('Received unknown category');
};

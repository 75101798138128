import React from 'react';
import { QBadge, QCheckbox, deprecated, QSelect, QSelectItem, QTag } from '@qualio/ui-components';
import { StopPropagation } from '../../../../utils/events';
import {
  ReassignItemsMap,
  ReassignDocumentsMap,
  PotentialNewOwners,
  AllCategories,
  ReassignDocument,
  ReassignChangeRequestsMap,
} from '../../types';
import { getAssociationStatus } from '../../../../utils/translator';
import { ColumnLabels } from './__displayStrings__/TableBuilder';
import { buildDispatchObject, EntityDispatch } from '../../state';

type CheckBoxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => void;

// Builds the column data for both "item" and "doc" tables
export const BuildColumns = (
  category: AllCategories,
  allSelected: boolean,
  handleHeaderCheckboxChange: CheckBoxChangeHandler,
) => {
  const tableType = category === 'owner' || category === 'reviewer' || category === 'approver' ? 'doc' : 'item';

  const checkBoxColumn = {
    Header: (
      <QCheckbox
        aria-label="table_checkbox"
        size="md"
        isChecked={allSelected}
        onClick={StopPropagation}
        onChange={handleHeaderCheckboxChange}
      />
    ),
    disableSortBy: true,
    width: '2%',
    accessor: 'checkbox',
  };
  const idColumn = {
    Header: ColumnLabels.id,
    width: '5%',
    accessor: 'documentCode',
  };
  const titleColumn = {
    Header: ColumnLabels.title,
    accessor: 'title',
    width: tableType === 'item' ? '18%' : '12%',
  };
  const newOwnerColumn = {
    Header: ColumnLabels.newOwner,
    accessor: 'newOwner',
    width: tableType === 'item' ? '5%' : '15%',
  };
  const statusColumn = {
    Header: ColumnLabels.status,
    accessor: 'status',
    width: '10%',
  };
  const tagColumn = {
    Header: ColumnLabels.tags,
    accessor: 'tags',
    width: '5%',
  };

  const itemColumns = [checkBoxColumn, idColumn, titleColumn, newOwnerColumn];
  const docColumns = [checkBoxColumn, idColumn, titleColumn, statusColumn, tagColumn, newOwnerColumn];

  return tableType === 'item' ? itemColumns : docColumns;
};

// Builds the row data for both "item" and "doc" tables
export const BuildRows = (
  category: AllCategories,
  dispatch: EntityDispatch,
  entities: ReassignDocumentsMap | ReassignItemsMap | ReassignChangeRequestsMap,
  selected: Set<number>,
  setSelected: (selectedItems: Set<number>) => void,
  users: PotentialNewOwners[],
): deprecated.QDataRow[] => {
  return Object.keys(entities).map((entityId) => {
    const qualioEntity = entities[entityId];

    const { id, newOwner, code, title } = qualioEntity;
    const currentValue = newOwner ? newOwner.id.toString() : undefined;

    const selectBoxChangeHandler = (changedItem: QSelectItem) => {
      const newMap = {
        ...entities,
        [id]: {
          ...qualioEntity,
          newOwner: {
            id: Number(changedItem.value),
            name: changedItem.label,
          },
        },
      };

      dispatch(buildDispatchObject(category, newMap));
    };

    const checkboxChangeHandler = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
      const newSelected = new Set([...selected]);

      if (changeEvent.currentTarget.checked) {
        newSelected.add(id);
      } else {
        newSelected.delete(id);
      }

      setSelected(newSelected);
    };

    const baseRow = {
      checkbox: (
        <QCheckbox
          aria-label="table_checkbox"
          size="md"
          isChecked={selected.has(id)}
          onChange={checkboxChangeHandler}
        />
      ),
      id,
      documentCode: <QBadge>{code}</QBadge>,
      title,
      newOwner: (
        <QSelect
          aria-label="documents_single_select"
          value={currentValue}
          options={users}
          onChange={(item) => item && selectBoxChangeHandler(item)}
        />
      ),
    };

    if (category === 'issues' || category === 'tasks' || category === 'changeRequestOwner') {
      return baseRow;
    }

    const { status_id: statusId, tags } = qualioEntity as ReassignDocument;

    return {
      ...baseRow,
      status: getAssociationStatus(statusId),
      tags: (
        <>
          {tags.map((tag) => (
            <QTag key={tag.id}>{tag.name}</QTag>
          ))}
        </>
      ),
    };
  });
};

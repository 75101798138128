import React, { ReactElement, useMemo } from 'react';
import styled from 'styled-components';
import { ConfirmationModal } from '../../../containers/confirmation-modal';
import { EntitiesState } from '../state';
import { CommonProps, PotentialNewOwners, ReassignDocument } from '../types';
import * as DisplayStrings from './__displayStrings__';

type SubmitHandlerProps = Pick<CommonProps, 'users' | 'groupMap' | 'onSubmit'> & {
  submitInProgress: boolean;
  setSubmitInProgress: (inProgress: boolean) => void;
  entityState: EntitiesState;
};

const UnorderedList = styled.ul`
  padding-left: 2rem;
`;

/**
 * This component handles the actual submission of the reassign qualio entities stepper
 * when the stepper attempts to submit, if it detects an assignee will be added to new groups
 * as a result of reassigning entities, it will prompt the current user to confirm
 */
const SubmitHandler = ({
  users,
  groupMap,
  submitInProgress,
  setSubmitInProgress,
  entityState,
  onSubmit,
}: SubmitHandlerProps): ReactElement | null => {
  const {
    owner: ownerItems,
    changeRequestOwner: changeRequestOwnerItems,
    approver: approverItems,
    reviewer: reviewerItems,
    issues: issueItems,
    tasks: taskItems,
  } = entityState;
  const ownerItemArray = useMemo(() => Object.values(ownerItems), [ownerItems]);
  const changeRequestOwnerItemsArray = useMemo(() => Object.values(changeRequestOwnerItems), [changeRequestOwnerItems]);
  const approverItemArray = useMemo(() => Object.values(approverItems), [approverItems]);
  const reviewerItemArray = useMemo(() => Object.values(reviewerItems), [reviewerItems]);
  const issueItemArray = useMemo(() => Object.values(issueItems), [issueItems]);
  const taskItemArray = useMemo(() => Object.values(taskItems), [taskItems]);
  const pullGroupName = (groupId: number) => groupMap[groupId].name;

  /**
   * This Reducer is used to take all of the Documents being reassigned to users
   * determine which users will be assigned to new groups as a result. Resulting object looks like:
   * {
   *  userId: new Set(['foo group', 'bar group', 'baz group'])
   * }
   */
  const reduceDocuments = (
    aggregate: Record<string, Set<string>>,
    { newOwner, accessible_by_group_ids: accessibleByGroupIds }: ReassignDocument,
  ) => {
    const newOwnerId = newOwner ? `${newOwner.id}` : null;
    const findUserById = (userObject: PotentialNewOwners) => userObject.value === newOwnerId;
    const user = users.find(findUserById);
    const userGroups = user ? user.groups : [];
    const filterOutGroupsUserBelongsTo = (groupId: number) => {
      return !userGroups.find((userGroupItem) => userGroupItem.id === groupId);
    };

    const groupsUserNeeds = accessibleByGroupIds.filter(filterOutGroupsUserBelongsTo).map(pullGroupName);

    if (!groupsUserNeeds.length || !newOwnerId) {
      return aggregate;
    }

    const existingGroupsUserNeeds = aggregate[newOwnerId] || new Set();

    return {
      ...aggregate,
      [newOwnerId]: new Set([...existingGroupsUserNeeds, ...groupsUserNeeds]),
    };
  };

  const groupAssignments = useMemo(() => {
    const allDocuments = [...ownerItemArray, ...approverItemArray, ...reviewerItemArray];

    return allDocuments.reduce(reduceDocuments, {});
  }, [ownerItemArray, approverItemArray, reviewerItemArray]);

  const submitChanges = async () => {
    try {
      await onSubmit({
        owner: ownerItemArray,
        changeRequestOwner: changeRequestOwnerItemsArray,
        approver: approverItemArray,
        reviewer: reviewerItemArray,
        issues: issueItemArray,
        tasks: taskItemArray,
      });
    } finally {
      setSubmitInProgress(false);
    }
  };

  // If "submitInProgress" is false, returns null so nothing is put into the DOM
  if (!submitInProgress) {
    return null;
  }

  const usersGettingNewGroups = Object.keys(groupAssignments)
    .map((userId) => users.find((userObject) => userObject.value === userId))
    .filter(Boolean) as PotentialNewOwners[];

  /**
   * If there are no values in usersGettingNewGroups, then we do not need to show
   * the warning modal, so the component can just submit the changes and return
   * null.
   */
  if (!usersGettingNewGroups.length) {
    submitChanges();

    return null;
  }

  /**
   * When a "Submit" happens that would result in new groups being assigned to a
   * user, we show the confirmation modal before submitting the changes
   */
  return (
    <ConfirmationModal
      title={DisplayStrings.Title}
      closeModal={() => setSubmitInProgress(false)}
      buttonProps={{
        label: DisplayStrings.Confirm,
        onClick: () => {
          submitChanges();
        },
      }}
    >
      {DisplayStrings.WarningMessage}
      <UnorderedList>
        {usersGettingNewGroups.map((user) => {
          const { value: userId, label: userName } = user;
          const groupNames = Array.from(groupAssignments[userId]);

          return (
            <li key={userId}>
              <DisplayStrings.UserAddedToGroupsMessage userName={userName} groupNames={groupNames} />
            </li>
          );
        })}
      </UnorderedList>
    </ConfirmationModal>
  );
};

export default SubmitHandler;

import { z } from 'zod';
import { MTBEUserCompanySchema } from './mtbe-user-company';

export const MTBEUserSchema = z
  .object({
    id: z.number(),
    email: z.string(),
    full_name: z.string(),
    staff: z.boolean(),
    companies: MTBEUserCompanySchema.array(),
  })
  .passthrough();

export type MTBEUserType = z.infer<typeof MTBEUserSchema>;
export const validateMTBEUser = (user: unknown): MTBEUserType => MTBEUserSchema.parse(user);

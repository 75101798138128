import React, { ReactElement, useEffect, useState, useMemo, useRef } from 'react';
import { deprecated, QHeading, QSelectItem } from '@qualio/ui-components';
import { QStackContainer } from '../../../../styles/Users.styles';
import {
  ReassignItemsMap,
  ReassignDocumentsMap,
  CommonProps,
  ItemCategories,
  DocCategories,
  ReassignChangeRequestsMap,
} from '../../types';
import { StepStatusOptions } from '../types';
import { AllEntitiesInMapReassigned } from '../../utils';
import { BuildColumns, BuildRows } from './TableBuilder';
import BulkSelect from './BulkSelect';
import { buildDispatchObject, EntityDispatch } from '../../state';

type BaseProps = Pick<CommonProps, 'users' | 'setNextButtonEnabled' | 'incrementStep'> & {
  dispatch: EntityDispatch;
  title: string;
  updateStepStatus: (status: StepStatusOptions) => void;
};

type ItemProps = {
  items: ReassignItemsMap;
  category: ItemCategories;
} & BaseProps;

type DocProps = {
  items: ReassignDocumentsMap | ReassignChangeRequestsMap;
  category: DocCategories;
} & BaseProps;

// Using named function instead of arrow function due to overloading
/* eslint-disable react/function-component-definition */
/**
 * This component is in charge of actually reassigning the qualio entities to
 * new owners. It is in charge of enabling stepper's "Next" button when all items
 * have been reassigned, as well as updating the stepStatus on unMount
 */
function ReassignEntities(props: ItemProps): ReactElement;
function ReassignEntities(props: DocProps): ReactElement;
function ReassignEntities(props: ItemProps | DocProps): ReactElement {
  const { users, setNextButtonEnabled, incrementStep, title, items, dispatch, category, updateStepStatus } = props;

  const itemIds = useMemo(() => Object.values(items).map(({ id }) => id), [items]);
  const allItemsAssigned = useMemo(() => AllEntitiesInMapReassigned(items), [items]);
  const [selected, setSelected] = useState(new Set<number>());
  const bulkSelectEnabled = Boolean(selected.size);
  const selectAll = () => setSelected(new Set(itemIds));

  const clearSelected = () => {
    setSelected(new Set());
  };

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      selectAll();
    } else {
      setSelected(new Set());
    }
  };

  const columns = useMemo(
    () => BuildColumns(category, bulkSelectEnabled, handleHeaderCheckboxChange),
    [bulkSelectEnabled],
  );
  const tableData = useMemo(
    () => BuildRows(category, dispatch, items, selected, setSelected, users),
    [items, selected, users],
  );

  const updateOwnerOfSelectedItems = (newOwner: QSelectItem) => {
    const newOwnerId = Number(newOwner.value);
    const newOwnerName = newOwner.label;
    const newItems = Object.values(items).reduce((aggregate, item) => {
      const { id } = item;
      const updatedItem = selected.has(id)
        ? { ...item, newOwner: { id: newOwnerId, name: newOwnerName } }
        : { ...item };

      return {
        ...aggregate,
        [id]: updatedItem,
      };
    }, {});

    dispatch(buildDispatchObject(category, newItems));
  };

  // Watch for changes to wether or not all items in the step have been assigned
  useEffect(() => {
    setNextButtonEnabled(allItemsAssigned);
  }, [allItemsAssigned]);

  /**
   * Component clean up to set step status when user leaves the page. The useRef
   * is necessary so the unmount process doesn't use the cached value of
   * "allItemsReassigned"
   */
  const allItemsAssignedRef = useRef(allItemsAssigned);

  allItemsAssignedRef.current = allItemsAssigned;
  useEffect(() => {
    return function setStatusOnUnmount() {
      return allItemsAssignedRef.current ? updateStepStatus('completed') : updateStepStatus('invalid');
    };
  }, []);

  return (
    <QStackContainer direction="column" spacing={3}>
      <QHeading size="md">{title}</QHeading>
      {bulkSelectEnabled && (
        <BulkSelect
          incrementStep={incrementStep}
          selected={selected}
          users={users}
          updateOwnerOfSelectedItems={updateOwnerOfSelectedItems}
          clearSelected={clearSelected}
          allItemsAssigned={allItemsAssigned}
        />
      )}
      <deprecated.QDataTable columns={columns} data={tableData} hasPagination={false} />
    </QStackContainer>
  );
}
/* eslint-enable react/function-component-definition */

export default ReassignEntities;
